import styled from 'styled-components';
import { SaveButton } from '../../InputGoals/styles';
import { ImageUrl } from '../../../utils/constants';

const BoldFont = 'Rubik-Bold';
const MediumFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Regular';
const EventContainer = styled.div`
  float: left;
  width:${({ width }) => width ? width : 'calc((100% - 48px) / 3)'};
  background-color: white;
  box-shadow: ${({ boxShadow }) => boxShadow ? '0px 16px 20px 0px #0D427026' : ''};
  position: relative;
  margin: 10px opx 10px 0;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-right: ${({ margin }) => margin ? '0px' : '25px'};
  margin-right: ${({ margin  }) => margin };
  .eventLabel{
    height:0px;
    width:100%;
    position: relative;
    left: 10px;
    top: 10px;

    .label{
    min-height:24px;
    width:100px;
    display:flex;
    background:white;
    border-radius:3px;
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color:#0D4270;
    position:relative;
    align-items: center;
    justify-content: center;
    top:10px;
    left:10px;
    }
  }

  .educateImageBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 254px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px 6px 0 0;
    .getStarted {
      font-family: 'Rubik-Medium';
      font-weight: 500;
      font-size: 18px;
      line-height: 92%;
      letter-spacing: 0px;
      min-width: 150px;
      height: 40px;
      background: linear-gradient(227.68deg, #00F0FF 15.32%, #3586FF 85.04%);
      color: white;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align:center;
      padding:0px 15px;
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  float: left;
  height: 100%;
  cursor: pointer;

  @media (max-width: 1200px) and (min-width: 768px) {
    object-fit: cover;
  }
`;

const EventContentContainer = styled.div`
  padding: 10px;
  padding: ${({padding}) => padding ? '15px 0 0px 0' : '16px'};
  float: left;
  width: 100%;
  display: block;
`;

const EventDate = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 28px;
  margin-top: 10px;
  float: left;
  width: 100%;
  padding: 0px 15px;

  i {
    margin-right: 3px;
  }
  
  >div:first-child{
    width: 100%;
    display: flex;
    line-height: 0px;
    justify-content: space-between;
    margin: 0px 0px 10px;
     >div:first-child{
    float: left;
    display: flex;
    > span {
      float: left;
      font-family: ${RegularFont};
      font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #0D4270;
    }
  }
  >div:last-child{
    display: flex;
    justify-content: flex-end;
    >span{
      font-family: Rubik-Medium;
      font-size: 16px;
      line-height: 19px;
      color: #F6B479;
    }}
}

  >div:last-child{
    width: 100%;
    display: flex;
    line-height: 0px;
     >div:first-child{
    float: left;
    width: 73%;
    display: flex;
    > span {
      float: left;
      font-family: ${RegularFont};
      font-size: 14px;

      font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
color: #0D4270;
    }
  }
  >div:last-child{
    width: 27%;
    display: flex;
    justify-content: flex-end;
    >span{
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #F6B479;
    }
   
}
  span:last-child {
    color: #0D4270;
    font-family: ${RegularFont};
    font-size: 14px;
  }
`;

const EventContent = styled.div`
  float: left;
  display: block;
  width: 100%;
  
  > span {
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0px;
    color: #0D4270;
    font-family: ${MediumFont};
    float: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    max-height: 50px;
    line-height: 30px;
  }
  .rowContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 15px;
    > span {
      text-transform: capitalize;
      cursor: pointer;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #0D4270;
      font-family: ${MediumFont};


font-size: 16px;
line-height: 20px;
color: #0D4270;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
padding-right: 5px;
    }
    i{
      font-size: 24px;
      text-align: right;
      color: #9c9c9c;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
    >img{
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
`;

const AddCalenderButton = styled(SaveButton)`
  margin-top: 10px;
  padding: 0;
  font-size: 18px;
  background: ${(props) => props.color || '#69C2FF'};
  border: ${(props) => props.color ? 'none' : ' 1px solid #ef6827'};
  width: 48%;
  height: 35px;
  font-family: ${MediumFont};
  display: block;
  border-radius: 3px;
  text-align: center;
  margin-left: auto;
  text-transform: ${(props) => props.color ? 'capitalize' : 'capitalize'};

height: 48px;
border-radius: 3px;
  margin-bottom: 15px;
  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const EventJoiningTime = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
  text-align: center;
  background: rgba(156, 156, 156, 0.1);
  > div {
    > span {
      color: #ef6827;
      font-family: ${BoldFont};
    }
  }
  .eventText{
    padding: 10px;
    >span{
      color: #FD7175;
      font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
    }
  }
`;

const DescriptionContainer = styled.div`
  width: 100%;
  padding: 0px 25px 0px 25px;
  height: auto;

  > span {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 20px;
    color: #005c87;
    font-family: rubik-medium;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
  }
  
  > div {
    color: rgb(153,153,153);
    margin-top: 15px;
    overflow: hidden;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
>span{
  >p{
    margin:0px;
    font-family: Rubik;
font-size: 16px;
line-height: 20px;
color: #005c87;
text-align: center;
  }
}
    @media (max-width: 766px) {
      height: auto;
      display: block;
    }
  }

  @media (max-width: 766px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  float: left;
  width: 100%;
  height: 254px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (max-width: 1200px) and (min-width: 768px) {
    height: auto;
  }

  @media (max-width: 767px) and (min-width: 530px) {
    width: 50%;
    height: 280px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    position: absolute;
    height: 100% !important;
  }

  div{
    > img {
    width: 100%;
    float: left;
    height: 100%;
    cursor: pointer;
    border-radius: 6px 6px 0 0;
  
    @media (max-width: 1200px) and (min-width: 768px) {
      object-fit: cover;
    }
  }

  }
  
  > img {
    width: 100%;
    float: left;
    height: 100%;
    cursor: pointer;
    border-radius: 6px 6px 0 0;
  
    @media (max-width: 1200px) and (min-width: 768px) {
      object-fit: cover;
    }
  }
`;

const CalendarButton = styled.div`
  width: 48%;
  display: flex;
  font-size: 18px;
  border-radius: 3px;
  height: 35px;
  justify-content: center;
  background: ${({ backgroundColor }) => backgroundColor ? backgroundColor : 'white'};
  height: 48px;
  padding: ${({ padding }) => padding ? padding : '10px 0px'};
  margin-top: 10px;
  color: white;
  font-family: rubik-medium;
  margin-bottom: 15px;
  cursor: ${({ pointer }) => pointer ? 'pointer' : ''};
  align-items:center;
  text-align:center;
`;

const EventContainerNew = styled.div`
  float: left;
  width: calc((100% - 25px) / 2);
  background-color: white;
  box-shadow: ${({ boxShadow }) => boxShadow ? '0px 16px 20px 0px #0D427026' : ''};;
  position: relative;
  margin: 10px opx 10px 0;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-right: ${({ margin }) => margin ? '0px' : '15px'};
  margin-right: ${({ margin  }) => margin }
`;

const EventContentV2 = styled.div`
  float: left;
  display: block;
  width: 100%;
  
.dateTime{
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color:#005c87;
padding: 8px 0px 16px 0px;
}

  > span {
    text-transform: capitalize;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 0px;
    color: #0D4270;
    font-family: ${MediumFont};
    float: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
    max-height: 50px;
    line-height: 24px;
  }
  .rowContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0px;
    padding: ${({ padding }) => padding ? '0px' : '10px'};
    .title{
      text-transform: capitalize;
      cursor: pointer;
      font-family: ${MediumFont};
      font-size: 18px;
      line-height: 24px;
      color: #005c87;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 5px;


    }

    ${'' /* > span {
      text-transform: capitalize;
      cursor: pointer;
      font-family: ${MediumFont};
      font-size: 18px;
      line-height: 24px;
      color: #0D4270;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 5px;
      width:93%;
    } */}

    i{
      font-size: 24px;
      text-align: right;
      color: #9c9c9c;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
    >img{
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
  .pointsEarned{
    width:100%;
    padding: 12px 0px;
    background: rgba(156, 156, 156, 0.1);
    text-align:center;
    align-items:center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #005c87;
    border:none;
    background:rgba(244, 170, 169, 0.15);
    color:#005c87;
    border-radius:6px;
    .points{
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #005c87;
    }
    >span{
      font-size: 24px;
      line-height:24px;
    }
  }
  .notEarned{
    width:100%;
    padding: 8px 0px;
    background: rgba(156, 156, 156, 0.1);
    text-align:center;
    align-items:center;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fd7175;
  }
`;

const CoachCard = styled.div`
  width: 100%;
  height: 254px;
  padding: 16px;
  display:flex;
  align-items:center;
  background-image: url(${ImageUrl}/${'event-section/coachbackground.png'});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  .cardWrapper{
    display:flex;
    width:100%;
    margin-top: 0px !important;

    .imagecard{
      width:164px;
      height:164px;
      background:white;
      border-radius:50%;
      display:flex;
      align-items:center;
      padding: 4px;
      >img{
        width:100%;
        height:100%;
        border-radius:50%;
      }
    }

    .coach-details{
      display:flex;
      align-items:center;
      flex-wrap:wrap;
      padding-left:16px;
      flex-direction:column;
      justify-content:center;
      width: calc(100% - 164px);
      .title{
        width:100%;
        display:flex;
        color: #FFF;
        text-align: center;
        font-family: Rubik-Medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .info{
        color: #FFF;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width:100%;
        display:flex;
        padding-top:8px;
      }
    }

  }
`;

export { EventContainer, EventContentContainer, EventDate, EventContent, AddCalenderButton, EventJoiningTime,
  DescriptionContainer, StyledImage, ImageContainer, CalendarButton, EventContainerNew, EventContentV2, CoachCard};
