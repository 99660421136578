/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { EventContainer, ImageContainer, EventContentContainer, EventContent, EventDate, AddCalenderButton, EventJoiningTime, CalendarButton, EventContainerNew, EventContentV2, CoachCard } from './styles';
import AddInCalendar from '../../AddInCalendar';
import ImageThumbnail from '../../ImageThumbnail';
import { getTimeToShow, convertDateInTimezone, convertMonthFromDate } from '../../../utils/methods';
import { ImageUrl } from '../../../utils/constants';
import { withTranslation } from 'react-i18next';
import AboutTheEventModal from '../AboutTheEventModal';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { getActiveEvents } from '../../../redux/actions';
import LazyImage from '../../common/LazyImage/LazyImage';
import { EyeIcon, InfoIconV2 } from '../../../utils/icons';

class SingleEvent extends Component {
  constructor() {
    super();
    this.state = {
      time: [],
      showModal: false,
      addToCalendar: 0
    }
  }

  componentDidMount() {
    const { event } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(event, 'event')
      })
    }, 1000);
    // this.props.getActiveEvents(1);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleEventAttending = (eventId, isAttending) => {
    this.props.attendEvent(eventId, isAttending, 'events');
    this.setState({
      addToCalendar: !this.state.addToCalendar
    })
    this.props.getActiveEvents(1);
  };
  toggleModal = () => {
    this.setState((prev) => ({ showModal: !prev.showModal }))
  }

  onLeaveMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  render() {
    const { event, history, t, length, margin,view } = this.props;
    const { showModal,onHover } = this.state;
    const slug = event.id;
    const cardLength = length + 1;
    const lang = localStorage.getItem("lang");

    return (
      event.event_status === 'over' ? <EventContainer width={this.props.width} margin={(cardLength) % (this.props.cards ? this.props.cards : 3) === 0 ? "0px" : (margin ? margin : "24px")} boxShadow={event.event_status === 'over' ? '1' : '0'} onMouseEnter={() => this.onEnterMouse(event.id)} onMouseLeave={() => this.onLeaveMouse()} >
        {/* <div className="eventLabel" style={{left:'0',top:'0'}}>
          <div className="label" style={{color:'#005c87'}}>{event.is_global_event=="1"?t("Woliba Live"):t("Company Live")}</div>
        </div> */}
        <ImageContainer>
          
          {event?.coach_details?<CoachCard>
            <div className='cardWrapper'>
              <div className='imagecard'>
                <img src={`${ImageUrl}/${event?.coach_details?.image}`} />
              </div>
              <div className='coach-details'>
                <div className='title'>{event?.coach_details?.name}</div>
                <div className='info'>{event?.coach_details?.education}</div>
              </div>
            </div>
          </CoachCard>:
            <LazyImage
              src={`${ImageUrl}/${event.image}`}
              alt={event.title}
              onClick={() => history.push(`/events/${slug}`)}
            />}
          
        </ImageContainer>
        {/* <div className='educateImageBackground' onClick={() => this.videoModal()}>
          <div className="getStarted" style={{color:"#005c87",background:'white',fontSize:"16px"}}>{t("Launch Class")}</div>
        </div> */}
        {onHover === event.id &&
            <div className='educateImageBackground' onClick={() => history.push({ pathname: `/events/${slug}`, state: view === "admin" ? {view:"admin"} : event })}>
              <div className="getStarted" style={{color:"white",background:'#007AB1',fontSize:"16px", width:"100%", margin:"0px 16px"}}><span style={{marginRight:"12px"}}>{EyeIcon()}</span>{t("View Details")}</div>
            </div>
        }
        <EventContentContainer >
          <EventContentV2 padding>
            <div className={"rowContainer"}>
              <span className='title' onClick={() => history.push({ pathname: `/events/${slug}`, state: event })} >{t(event.title)}</span>
              <span onClick={() => this.toggleModal()}>{InfoIconV2()}</span>
              {/* <img src="/public/images/NewDashboardV2/infoIcon.png" alt="info" onClick={() => this.toggleModal()}/> */}
            </div>
            <div className='dateTime' style={{color:"#005c87"}}>
              { (lang !== 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('MMM DD, hh:mm a'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('DD MMM hh:mm a'), this.props))} | {event.event_location ? t('Onsite Event') : t('Virtual Event')}
            </div>
            {event.is_attended?<div className='pointsEarned' style={{background:"rgba(118, 171, 120, 0.15)"}}>
              <span style={{fontSize:"20px"}}><img src={ImageUrl + "/images/emoji/happy-emoji.svg"}/>&nbsp;</span>{t("You earned")}<span className='points'>+{event.event_points} Social Points</span>
              {/* <span style={{fontSize:"20px"}}>🤩 &nbsp;</span> {t("You earned")} <span className='points' style={{color:"#76AB78"}}>+{event.event_points} {t("Social Points")}</span> {t("for participating!")} */}
            </div>:<div className='pointsEarned' style={{border:"none",background:"#fce6e6",color:"#005c87",borderRadius:'6px'}}><span><img src={ImageUrl + "/images/emoji/sad-emoji.svg"}/>&nbsp;</span>{this.props.t("You did not attend")}</div>}
          </EventContentV2>
        </EventContentContainer>
        <AboutTheEventModal showModal={showModal} onHide={this.toggleModal} title={event && event.title || ''} body={event && event.body || ''} />
      </EventContainer> :
        <EventContainerNew margin={(cardLength) % 2 === 0 ? "0px" : "25px"} boxShadow={event.event_status === 'over' ? '1' : '0'}>
          <ImageContainer>
            <ImageThumbnail
              src={`${ImageUrl}/${event.image}`}
              alt={event.title}
              onClick={() => history.push(`/events/${slug}`)}
            />
          </ImageContainer>
          <EventContentContainer padding={event.event_status === 'over' ? '1' : '0'}>
            <EventContent>
              <div className={"rowContainer"}>
                <span className='title' onClick={() => history.push({ pathname: `/events/${slug}`, state: event })}>{event.title}</span>
                <span onClick={() => this.toggleModal()}>{InfoIconV2()}</span>
                {/* <img src="/public/images/NewDashboardV2/infoIcon.png" alt="info" onClick={() => this.toggleModal()}/> */}
              </div>
              <EventDate>
                <div className={"last"}>
                  <div>
                    <span>{t("Date")}:&nbsp;</span>
                    <span>{ (lang != 'fr') ? (convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('MMM DD, YYYY'), this.props)) : (convertMonthFromDate(moment(convertDateInTimezone(event && event.start_date)._d).format('DD MMM YYYY'), this.props))}</span>
                  </div>
                  <div>
                    <span>{event.event_location ? t('Onsite Event') : t('Virtual Event')}</span>
                  </div>
                </div>
                <div className={"last"}>
                  <div>
                    <span>{t("Time")}:&nbsp; </span>
                    <span>{convertMonthFromDate(moment(convertDateInTimezone(event.start_date)._d).format('hh:mm a'), this.props)} - {moment(convertDateInTimezone(event.end_date)._d).format('hh:mm a')} ({(event.abbreviation)})</span>
                  </div>
                  <div></div>
                </div>
              </EventDate>
              <div className={"rowContainerNew"}>
                {event.event_status === 'over' ? <EventJoiningTime>
                  {
                    (event.event_status === 'over' || convertDateInTimezone(event.end_date)._d <= moment()._d) ? <div className={"eventText"}><span>{t("This Event Has Ended")}</span></div> :
                      convertDateInTimezone(event.start_date)._d <= moment()._d ? <div><span>{t("Event is in progress")}</span></div> :
                        <div>
                          <span>
                            {time.map((step, index) => step + (index === (time.length - 1) ? '' : ', '))}
                          </span> {t("until this event will start.")}
                        </div>
                  }
                </EventJoiningTime>
                  : null}
              </div>
              <div className={"rowContainer"}>
                {event.is_attending !== 1 ? event.event_status !== 'over' && <CalendarButton backgroundColor={"rgba(156,156,156,0.4)"} padding="0px">{t("Add to calendar")}</CalendarButton> :
                  event.event_status !== 'over' &&
                  <CalendarButton backgroundColor={"rgb(246,180,121)"} pointer={"1"}><AddInCalendar event={event} /></CalendarButton>
                }
                {event.event_status !== 'over' && <AddCalenderButton
                  rsvpButton
                  color={event.is_attending === 1 ? 'linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%)' : '#69C2FF'}
                  onClick={() => this.handleEventAttending(event.id, event.is_attending)}
                  disabled={event.event_status === 'over' || convertDateInTimezone(event.end_date)._d <= moment()._d}
                >
                  {event.is_attending === 1 ? t('Attending') : t('RSVP')}
                </AddCalenderButton>}
              </div>
            </EventContent>
          </EventContentContainer>
          <AboutTheEventModal showModal={showModal} onHide={this.toggleModal} title={event && event.title || ''} body={event && event.body || ''} />
        </EventContainerNew>
    )
  }
}

SingleEvent.propTypes = {
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attendEvent: PropTypes.func.isRequired,
  t: PropTypes.func,
  length: PropTypes.number,
  getActiveEvents: PropTypes.func.isRequired,
  activeEvents: PropTypes.array,
  getPastEvents: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  width:PropTypes.string,
  cards:PropTypes.number,
  margin:PropTypes.string,
  view:PropTypes.string
};

const mapStateToProps = (state) => ({
  activeEvents: state.events.activeEvents,
});

const mapDispatchToProps = (dispatch) => ({
  getActiveEvents: (pageNumber) => dispatch(getActiveEvents(pageNumber)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SingleEvent)));